import { getInfo, postInfo } from './api';

export default {
  // 获取系统配置
  getSysConfig() {
    return getInfo('/system/getSysConfig');
  },
  // 获取用户列表
  saveSysConfig(data) {
    return postInfo('/system/saveSysConfig', data);
  },
};
