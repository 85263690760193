<template>
  <div class="valuation">
    <div
      v-loading="loading"
      class="body">
      <!-- 基本信息设置 -->
      <div class="section">
        <div class="section-title">基本信息设置</div>
        <div class="section-content">
          <div class="name">微信号</div>
          <el-input
            v-model="wechat"
            clearable
            :maxlength="50"/>
        </div>
        <div class="section-content">
          <div class="name">电话</div>
          <el-input
            v-model="phone"
            clearable
            :maxlength="50"/>
        </div>
      </div>

      <el-button
        class="save-button"
        type="primary"
        @click="saveSysConfig">保存</el-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/SystemConfigApi';

export default {
  name: 'SystemConfig',
  components: {

  },
  data() {
    return {
      loading: false,
      wechat: '',
      phone: '',
    };
  },
  watch: {

  },
  created() {
    this.getSysConfig();
  },
  methods: {
    getSysConfig() {
      this.loading = true;
      api.getSysConfig().then(res => {
        this.wechat = res.result.wechat;
        this.phone = res.result.phone;
        this.loading = false;
      }).catch(err => {
          console.log(err);
          this.loading = false;
          this.$message.error(err.message);
      });
    },
    saveSysConfig() {
      if (!this.wechat) {
        this.$message.warning('微信号不能为空');
        return;
      }
      if (!this.phone) {
        this.$message.warning('电话不能为空');
        return;
      }
      this.loading = true;
      api.saveSysConfig({
        wechat: this.wechat,
        phone: this.phone,
      }).then(() => {
          this.loading = false;
          this.$message.success('操作成功');
          this.getSysConfig();
      }).catch(err => {
          console.log(err);
          this.loading = false;
          this.$message.error(err.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  -webkit-box-flex: 1;
  flex: 1;
  overflow-y: auto;
  padding: 40px 30px 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  position: relative;
}
.save-button {
  margin-left: 230px;
}

.section + .section {
  margin-top: 50px;
}

.section-title {
  color: #333;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 30px;
}

.section-content {
  margin-bottom: 30px;
  .name {
    color: #333;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .el-input {
    width: 300px;
  }
}
</style>
